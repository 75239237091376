import "../assets/css/Loader.css";
const Loader=()=>
{
    return(
        <div className="loader">
            <div class="dot-flashing"></div>
        </div>
    )
}

export default Loader;